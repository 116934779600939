.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: black;
  font-family: "Lato", sans-serif;
}

.LoginGuest {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: #F9A01B;
  font-family: "Lato", sans-serif;
}

.QRCodePage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: #104B89;
  font-family: "Lato", sans-serif;
}

.LogoutPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: #104B89;
  font-family: "Lato", sans-serif;
}

.LoginNlex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: #F9A01B;
  font-family: "Lato", sans-serif;
}

.CheckInPage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: #F9A01B;
  font-family: "Lato", sans-serif;
}

.UserCheckIn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: #F9A01B;
  font-family: "Lato", sans-serif;
}

.login-guest-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(16, 75, 137, 0.8);
  border-radius: 15px;
}

.logout-container {
  padding-top: 50px;
  padding-bottom: 50px;
  background: rgba(16, 75, 137, 0.8);
  border-radius: 15px;
}

.home-card {
  border-radius: 15px;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgba(16, 75, 137, 0.8);
}


.column-flex {
  display: flex !important;
  justify-content: center;
}

.margin-right-10 {
  margin-right: 10px;
}

.link-text {
  color: white;
  font-size: 20px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.facebook {
  color: blue !important;
}

.instagram {
  color: #9d02f2 !important;
}

.twitterX {
  color: black !important;
}

.links {
  text-decoration: none;
}

.link-column {
  margin-top: 100px !important;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.button-column {
  margin-top: 50px !important;
}

.mtb-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.form-control {
  width: 80%;
}

.submit-button {
  margin-top: 50px;
  font-weight: bold;
}

.invalid-feedback {
  font-size: 15px !important;
}

.datepicker-100 {
  width: 200% !important;
}

li {
  color: black;
  font-size: 14px;
}

.form-group label {
  text-align: right;
}

.navbar-bg {
  background-color: #104B89;
}

.navbar-color {
  color: white;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.homepage-buttons {
  background-color: #F9A01B;
  border-color: #104B89;
  color: black;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.login-guest-row {
  width: 80%;
  margin: auto;
}

.login-guest-container-row {
  text-align: center;
  width: 35%;
  margin: auto;
  background-color: #F9A01B;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  color: #104B89;
}

.login-guest-button {
  margin-right: 20px;
  background-color: #F9A01B;
  color: #104B89;
}

.login-nlex-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(16, 75, 137, 0.8);
  border-radius: 15px;
  max-width: 40%;
}

.login-nlex-container-row {
  text-align: center;
  width: 50%;
  margin: auto;
  background-color: #F9A01B;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  color: #104B89;
}

.login-nlex-row {
  width: 50%;
  margin: auto;
}

.login-nlex-button {
  margin-right: 20px;
  background-color: #F9A01B;
  color: #104B89;
}

.form-control-login-nlex {
  width: 100% !important;
}

.qrcode-container {
  padding-top: 50px;
  padding-bottom: 30px;
  background: rgba(16, 75, 137, 0.8);
  border-radius: 15px;
  max-width: 60%;
}

.qrcode-title {
  width: 80%;
  color: white;
  margin: auto;
  font-weight: bolder;
}

.qrcode-code {
  width: 256px;
  margin: auto;
  margin-top: 30px !important;
  text-align: center;
}

.qrcode-wifi {
  width: 80%;
  margin: auto;
  margin-top: 50px !important;
  color: #104B89;
  background-color: #F9A01B;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  font-weight: bolder;
}

.qrcode-list li {
  color: white;
}

.home-container {
  background: rgba(16, 75, 137, 0.8);
  max-width: 50% !important;
  height: 70vh !important;
}

.checkin-nlex-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(16, 75, 137, 0.8);
  border-radius: 15px;
  max-width: 40%;
}

.checkin-nlex-container-row {
  text-align: center;
  width: 50%;
  margin: auto;
  background-color: #F9A01B;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  color: #104B89;
}

.user-checkin-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(16, 75, 137, 0.8);
  border-radius: 15px;
  max-width: 40%;
}

.user-checkin-container-row {
  text-align: center;
  width: 50%;
  margin: auto;
  background-color: #F9A01B;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 10px;
  color: #104B89;
}

.checkin-title-row {
  text-align: center;
  font-weight: bolder;
  font-size: 35px;
  color: white;
}

.checkin-button-row {
  max-width: 30%;
  margin: auto;
}

.checkin-button {
  color: #104B89;
  background-color: #F9A01B;
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 50px;
}

.checkin-button-secondary {
  font-weight: bolder;
  font-size: 20px;
  margin-bottom: 50px;
}

.user-checkin-button-row {
  max-width: 30%;
  margin: auto;
  margin-top: 100px;
}

.user-checkin-col {
  max-width: 50%;
  text-align: center;
  font-size: 30px;
  color: white;
  font-weight: bolder;
  margin-left: 40px;
}

.user-checkin-grid {
  justify-content: center;
}

.home-grid-item {
  margin-bottom: 50px
}

.Checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-image: url('/public/images/nlex_bg.jpg');
  background-position: center;
  color: black;
  font-family: "Lato", sans-serif;
  height: 100vh;
}

.checkout-container {
  background: rgba(16, 75, 137, 0.8);
  border-radius: 15px;
  max-width: 40%;
  padding-bottom: 30px;
}

.checkout-container-row {
  background-color: #F9A01B;
  border-radius: 10px;
  color: #104B89;
  margin: auto;
  text-align: center;
  width: 50%;
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.checkout-button-row {
  max-width: 30%;
  margin: auto;
}

.dropdown-size {
  width: 80%;
}

.qrcode-button {
  text-align: center;
}

@media (max-width: 425px) {
  .home-container-small {
    max-width: 90% !important;
    height: 90vh !important;
  }

  .home-grid-item-small {
    margin-bottom: 0px;
  }

  .LoginGuest {
    height: auto;
  }

  .login-guest-container-small {
    width: 90% !important;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .login-guest-container-row {
    width: 90% !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .font-size-small {
    font-size: 20px !important;
  }

  .label-small {
    font-size: 15px !important;
  }

  .form-control {
    width: 100%;
  }

  .datepicker-100 {
    width: 140% !important;
  }

  .dropdown-size {
    width: 100%;
  }

  .submit-button {
    margin-top: 30px;
  }
  
  .QRCodePage {
    height: auto;
  }

  .qrcode-container {
    max-width: 90% !important;
    padding-top: 30px !important;
    margin-top: 30px !important;
    margin-bottom: 30px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }

  .qrcode-wifi {
    margin-top: 30px;
  }

  .qrcode-button {
    margin-top: 30px;
  }

  .login-nlex-container {
    max-width: 90%;
    padding-top: 30px !important;
    margin-top: 30px !important;
    margin-bottom: 30px;
  }

  .login-nlex-container-row {
    width: 90%;
    padding-top: 10px;
  }

  .login-nlex-row {
    width: 90%;
  }

  .UserCheckIn {
    height: auto;
  }

  .user-checkin-container {
    max-width: 90% !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .user-checkin-container-row {
    width: 90% !important;
    padding-top: 10px !important;
  }

  .user-checkin-col {
    max-width: 90% !important;
    margin-left: 0px;
    margin: auto;
  }

  .user-checkin-grid {
    margin-top: 50px !important;
  }

  .user-checkin-button-row {
    max-width: 60% !important;
    margin-top: 80px !important;
  }

  .Checkout {
    height: auto;
  }

  .checkout-container {
    max-width: 90% !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .checkout-container-row {
    width: 90% !important;
  }

  .checkout-button-row {
    max-width: 60% !important;
  }

  .checkin-nlex-container {
    max-width: 90% !important;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  .checkin-nlex-container-row {
    width: 90% !important;
  }

  .checkin-title-row {
    font-size: 30px !important;
  }

  .checkin-button-row {
    max-width: 60% !important;
  }
}